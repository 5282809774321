import polity_event_1 from 'assets/img/event/10/event-1.jpg';
import polity_event_2 from 'assets/img/event/10/event-2.jpg';
import polity_event_3 from 'assets/img/event/10/event-3.jpg';

export const blogHeader = {
    metaTitle: "Best 3D Modeling, 3D Art and 3D Animation Services in India",
    metaDesc: "We offer best 3D modeling, best 3D animation, texturing, and product presentation services across the industries.",
    metaKeywords: "best 3d animation in india, best 3d modeling services in delhi, best 3d motion design, 3d model cost, 3d animation price, free 3d models,  best 3d art design in noida, 3d product animation, 3d texturing services",
    pageTitle: "3D",
    canonical: "https://www.rionick.com/best-3d-services-india",
    t1: "We are here for you.",
    t2: "Specialize in offering custom 3D designs such as 3D modeling, 3D texturing and 3D animation"
}
export const blogsData = [
    {
        list:
            [
                "Product visualization",
                "Faster deliveries",
                "Increased accuracy",
                "Customization",
                "Cost reduction",
            ],
        title: "Does 3D modeling give you an edge over your competitors? 1",
        p: "3D Modelings Services includes but not limited to:",
        img: [polity_event_1,polity_event_2,polity_event_3],
        reverse: false,
        video: true,
        price: 100,
        btnText: "readmore",
        link: "https://google.com",
        linkText: "Watch This Model Demo here Click Now !",
        discount: 0,
        btnLink: "",
        videoId: "https://www.youtube.com/embed/O5E7tqCXYmE?si=eZ0AcbN4y5XTtVUf"
    },
    {
        btnText: "readmore",
        btnLink: "",
        list:
            [
                "Product visualization",
                "Faster deliveries",
                "Increased accuracy",
                "Customization",
                "Cost reduction",
            ],
        title: "Does 3D modeling give you an edge over your competitors? 2",
        p: "3D Modelings Services includes but not limited to:",
        img: ["https://media.istockphoto.com/id/1768244401/photo/mother-taking-her-daughter-to-school.jpg?s=1024x1024&w=is&k=20&c=6mcIjgiVr6_vvAt7ivrs9Oy4YYToMcwIhi9-6WveOLc=", "https://media.istockphoto.com/id/1768244401/photo/mother-taking-her-daughter-to-school.jpg?s=1024x1024&w=is&k=20&c=6mcIjgiVr6_vvAt7ivrs9Oy4YYToMcwIhi9-6WveOLc="],
        reverse: false,
        video: true,
        price: 100,
        discount: 0,
        videoId: "https://www.youtube.com/embed/O5E7tqCXYmE?si=eZ0AcbN4y5XTtVUf"
    },
    {
        btnText: "readmore",
        btnLink: "https://google.com/",
        price: 100,
        discount: 0,
        list:
            [
                "Product visualization",
                "Faster deliveries",
                "Increased accuracy",
                "Customization",
                "Cost reduction",
            ],
        title: "Does 3D modeling give you an edge over your competitors? 3",
        p: "3D Modelings Services includes but not limited to:",
        img: ["https://media.istockphoto.com/id/1768244401/photo/mother-taking-her-daughter-to-school.jpg?s=1024x1024&w=is&k=20&c=6mcIjgiVr6_vvAt7ivrs9Oy4YYToMcwIhi9-6WveOLc="],
        reverse: false,
        video: true,
        videoId: "https://www.youtube.com/embed/O5E7tqCXYmE?si=eZ0AcbN4y5XTtVUf"
    },
    {
        list:
            [
                "Product visualization",
                "Faster deliveries",
                "Increased accuracy",
                "Customization",
                "Cost reduction",
            ],
        title: "Does 3D modeling give you an edge over your competitors? 4",
        p: "3D Modelings Services includes but not limited to:",
        img: ["https://media.istockphoto.com/id/1768244401/photo/mother-taking-her-daughter-to-school.jpg?s=1024x1024&w=is&k=20&c=6mcIjgiVr6_vvAt7ivrs9Oy4YYToMcwIhi9-6WveOLc="],
        reverse: false,
        video: true,
        price: 100,
        btnText: "readmore",
        discount: 0,
        btnLink: "",
        videoId: "https://www.youtube.com/embed/O5E7tqCXYmE?si=eZ0AcbN4y5XTtVUf"
    },
    {
        btnText: "readmore",
        btnLink: "",
        list:
            [
                "Product visualization",
                "Faster deliveries",
                "Increased accuracy",
                "Customization",
                "Cost reduction",
            ],
        title: "Does 3D modeling give you an edge over your competitors? 5",
        p: "3D Modelings Services includes but not limited to:",
        img: ["https://media.istockphoto.com/id/1768244401/photo/mother-taking-her-daughter-to-school.jpg?s=1024x1024&w=is&k=20&c=6mcIjgiVr6_vvAt7ivrs9Oy4YYToMcwIhi9-6WveOLc=", "https://media.istockphoto.com/id/1768244401/photo/mother-taking-her-daughter-to-school.jpg?s=1024x1024&w=is&k=20&c=6mcIjgiVr6_vvAt7ivrs9Oy4YYToMcwIhi9-6WveOLc="],
        reverse: false,
        video: true,
        price: 100,
        discount: 0,
        videoId: "https://www.youtube.com/embed/O5E7tqCXYmE?si=eZ0AcbN4y5XTtVUf"
    },
    {
        btnText: "readmore",
        btnLink: "https://google.com/",
        price: 100,
        discount: 0,
        list:
            [
                "Product visualization",
                "Faster deliveries",
                "Increased accuracy",
                "Customization",
                "Cost reduction",
            ],
        title: "Does 3D modeling give you an edge over your competitors?6",
        p: "3D Modelings Services includes but not limited to:",
        img: ["https://media.istockphoto.com/id/1768244401/photo/mother-taking-her-daughter-to-school.jpg?s=1024x1024&w=is&k=20&c=6mcIjgiVr6_vvAt7ivrs9Oy4YYToMcwIhi9-6WveOLc="],
        reverse: false,
        video: true,
        videoId: "https://www.youtube.com/embed/O5E7tqCXYmE?si=eZ0AcbN4y5XTtVUf"
    },
    {
        list:
            [
                "Product visualization",
                "Faster deliveries",
                "Increased accuracy",
                "Customization",
                "Cost reduction",
            ],
        title: "Does 3D modeling give you an edge over your competitors?7",
        p: "3D Modelings Services includes but not limited to:",
        img: ["https://media.istockphoto.com/id/1768244401/photo/mother-taking-her-daughter-to-school.jpg?s=1024x1024&w=is&k=20&c=6mcIjgiVr6_vvAt7ivrs9Oy4YYToMcwIhi9-6WveOLc="],
        reverse: false,
        video: true,
        price: 100,
        btnText: "readmore",
        discount: 0,
        btnLink: "",
        videoId: "https://www.youtube.com/embed/O5E7tqCXYmE?si=eZ0AcbN4y5XTtVUf"
    },
    {
        btnText: "readmore",
        btnLink: "",
        list:
            [
                "Product visualization",
                "Faster deliveries",
                "Increased accuracy",
                "Customization",
                "Cost reduction",
            ],
        title: "Does 3D modeling give you an edge over your competitors? 8",
        p: "3D Modelings Services includes but not limited to:",
        img: ["https://media.istockphoto.com/id/1768244401/photo/mother-taking-her-daughter-to-school.jpg?s=1024x1024&w=is&k=20&c=6mcIjgiVr6_vvAt7ivrs9Oy4YYToMcwIhi9-6WveOLc=", "https://media.istockphoto.com/id/1768244401/photo/mother-taking-her-daughter-to-school.jpg?s=1024x1024&w=is&k=20&c=6mcIjgiVr6_vvAt7ivrs9Oy4YYToMcwIhi9-6WveOLc="],
        reverse: false,
        video: true,
        price: 100,
        discount: 0,
        videoId: "https://www.youtube.com/embed/O5E7tqCXYmE?si=eZ0AcbN4y5XTtVUf"
    },
    {
        btnText: "readmore",
        btnLink: "https://google.com/",
        price: 100,
        discount: 0,
        list:
            [
                "Product visualization",
                "Faster deliveries",
                "Increased accuracy",
                "Customization",
                "Cost reduction",
            ],
        title: "Does 3D modeling give you an edge over your competitors? 9",
        p: "3D Modelings Services includes but not limited to:",
        img: ["https://media.istockphoto.com/id/1768244401/photo/mother-taking-her-daughter-to-school.jpg?s=1024x1024&w=is&k=20&c=6mcIjgiVr6_vvAt7ivrs9Oy4YYToMcwIhi9-6WveOLc="],
        reverse: false,
        video: true,
        videoId: "https://www.youtube.com/embed/O5E7tqCXYmE?si=eZ0AcbN4y5XTtVUf"
    },
    {
        btnText: "readmore",
        btnLink: "https://google.com/",
        price: 100,
        discount: 0,
        list:
            [
                "Product visualization",
                "Faster deliveries",
                "Increased accuracy",
                "Customization",
                "Cost reduction",
            ],
        title: "Does 3D modeling give you an edge over your competitors? 10",
        p: "3D Modelings Services includes but not limited to:",
        img: ["https://media.istockphoto.com/id/1768244401/photo/mother-taking-her-daughter-to-school.jpg?s=1024x1024&w=is&k=20&c=6mcIjgiVr6_vvAt7ivrs9Oy4YYToMcwIhi9-6WveOLc="],
        reverse: false,
        video: true,
        videoId: "https://www.youtube.com/embed/O5E7tqCXYmE?si=eZ0AcbN4y5XTtVUf"
    },
    {
        btnText: "readmore",
        btnLink: "https://google.com/",
        price: 100,
        discount: 0,
        list:
            [
                "Product visualization",
                "Faster deliveries",
                "Increased accuracy",
                "Customization",
                "Cost reduction",
            ],
        title: "Does 3D modeling give you an edge over your competitors? 11",
        p: "3D Modelings Services includes but not limited to:",
        img: ["https://media.istockphoto.com/id/1768244401/photo/mother-taking-her-daughter-to-school.jpg?s=1024x1024&w=is&k=20&c=6mcIjgiVr6_vvAt7ivrs9Oy4YYToMcwIhi9-6WveOLc="],
        reverse: false,
        video: true,
        videoId: "https://www.youtube.com/embed/O5E7tqCXYmE?si=eZ0AcbN4y5XTtVUf"
    },

] 