import CustomeCreateBlog from 'components/blogs/CustomeCreateBlog'
import SEO from 'components/seo'
import ServiceThreeVideoArea from 'components/video-area/service-3-video-area'
import { blogHeader, blogsData } from 'data/data'
import FooterSeven from 'layout/footers/footer-7'
import HeaderSix from 'layout/headers/header-6'
import Wrapper from 'layout/wrapper'
import React from 'react'
import bannericon from "assets/img/Main Images/3d/rionickicon.png"
import banner3d from "assets/img/Main Images/3d/video-bg-2.png"
import Pagination from 'ui/Pagination'
import { useParams } from 'react-router-dom'

const Blog = () => {
    const { id } = useParams()

    // const Blog = blogsData.slice(id === undefined ? 0 : 5, id >= 1 ? 0 : id * 5)
    const startIndex = id ? (id - 1) * 5 : 0;

    // Use slice to get a portion of the array
    const Blog = blogsData.slice(startIndex, startIndex + 5);

    const movTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    return (
        <>
            <Wrapper>
                <SEO
                    metaTitle={blogHeader.metaTitle}
                    metaDesc={blogHeader.metaDesc}
                    metaKeywords={blogHeader.metaKeywords}
                    pageTitle={blogHeader.pageTitle}
                    canonical={blogHeader.canonical}
                />
                <HeaderSix top_bar={false} commonOffCanvas={true} />
                <ServiceThreeVideoArea
                    banner_icon={bannericon}
                    video_bg={banner3d}
                    t1={blogHeader.t1}
                    t2={blogHeader.t2}
                />
                {Blog && Blog.map((val, index) => {
                    return (
                        <div key={index}>
                            <CustomeCreateBlog
                                list={val.list}
                                t1={`${val.title}`}
                                p={val.p}
                                video={val.video}
                                videoLink={val.videoLink}
                                about_img={val.img}
                                reverse={val.reverse}
                                price={val.price}
                                discount={val.discount}
                                btnLink={val.btnLink}
                                btnText={val.btnText}
                                link={val.link}
                                linkText={val.linkText}
                            />
                        </div>
                    )
                })}
                <div className="tp-pagination tp-pagination-style-2 d-flex justify-content-center p-4">
                    <Pagination backToTop={movTop} url='shop' length={Math.ceil(blogsData.length / 5)} active={id === undefined ? 1 : id} />
                </div>


                <FooterSeven />
            </Wrapper>
        </>
    )
}

export default Blog