import React, { useState } from "react";
import { Image } from "react-bootstrap";
// internal
// import thumb_shape_1 from "assets/img/about/15/about-thumb-shape.png";
import thumb_shape_2 from "assets/img/about/15/about-thumb-shape-2.png";
import { Link } from "react-router-dom";
import VideoModal from "components/common/modals/modal-video";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import ProductDetailsPrice from "components/product-details/product-details-price";


const CustomeCreateBlog = ({
    t1 = "",
    subtitle = "",
    p = "",
    reverse = false,
    list = [],
    about_img = [], video = "", videoId = "",
    price = 0,
    discount = 0,
    btnLink = "",
    btnText = "",
    link = "",
    linkText = ""
}) => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <section key={Math.random()} className="about__area pt-130">
                <div className="container">
                    <div className={`row ${reverse && 'flex-row-reverse'}`}>
                        <>
                            <div className="col-xl-6 col-lg-6">
                                <div
                                    className="z-index-1 w-img wow fadeInUp"
                                    data-wow-delay=".3s"
                                    data-wow-duration="1s"
                                >
                                    <Swiper
                                        className="postbox__thumb postbox__slider swiper-container w-img p-relative"
                                        slidesPerView={1}
                                        spaceBetween={0}
                                        modules={[Navigation]}
                                        loop={true}
                                        autoplay={{
                                            delay: 3000,
                                        }}
                                        // Navigation arrows
                                        navigation={{
                                            nextEl: ".postbox-slider-button-next",
                                            prevEl: ".postbox-slider-button-prev",
                                        }}
                                    >
                                        {about_img.map((img, i) => (
                                            <SwiperSlide key={i} className="postbox__slider-item">
                                                <Image src={img} alt="slider img" />
                                            </SwiperSlide>
                                        ))}
                                        <div className="postbox__nav">
                                            <button className="postbox-slider-button-next">
                                                <i className="fa-regular fa-angle-right"></i>
                                            </button>
                                            <button className="postbox-slider-button-prev">
                                                <i className="fa-regular fa-angle-left"></i>
                                            </button>
                                        </div>
                                    </Swiper>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div
                                    className="about__wrapper-15 pl-45 wow fadeInUp"
                                    data-wow-delay=".6s"
                                    data-wow-duration="1s"
                                >
                                    <div className="tp-section-wrapper-3">
                                        <span className="tp-section-subtitle-3">{subtitle}</span>
                                        <h3 className="tp-section-title-3">{t1}</h3>
                                        <p>{p}</p>
                                    </div>
                                    <div className="about__list-2">
                                        {/* <div dangerouslySetInnerHTML={{ __html: text }}></div> */}
                                        <ul>
                                            {list.map((val) => {
                                                return (
                                                    <>
                                                        <li>{val}</li>{" "}
                                                    </>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                    {/* <Link className="tp-link-btn-2 position-none" to={link}>{linkText}</Link> */}
                                    <div className="fs-8">
                                        {/* <i class="tp fa-solid fa-greater-than fa-sm"></i> */}
                                        <i class="text-danger fa-regular fa-eye"></i>
                                        <Link className="m-2 text-danger fw-bold" to={link}>{linkText}</Link>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <div><Link to={btnLink}> <button className="tp-load-more-btn" style={{
                                            padding: "10px 35px"
                                        }}> {btnText}</button></Link></div>
                                        <div className="mt-2 pr-35">
                                            <ProductDetailsPrice price={price} discount={discount} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CustomeCreateBlog;
