import React from "react";
import { Link } from "react-router-dom";
// internal
import { NextArrowTwo, PrevArrowTwo } from "svg/index";

const Pagination = ({ url, length, active, backToTop }) => {
  const pages = Array.from({ length }, (_, index) => index + 1);
  return (
    <nav>
      <ul>
        <li>
          <Link onClick={backToTop} to={`/${url}/${active === 1 ? 1 : active > 1 ? active - 1 : 1}`} className="tp-pagination-prev prev page-numbers">
            <PrevArrowTwo />
            Prev
          </Link>
        </li>
        {pages.map((page) => (
          <li key={page}>
            <Link onClick={backToTop} to={`/${url}/${page}`}>
              {page === Math.floor(active) ? <span className="current">{page}</span> : page}
            </Link>
          </li>
        ))}
        <li>
          <Link onClick={backToTop} to={`/${url}/${active === 1 ? (Math.floor(active) + 1) : (Math.floor(active) + 1 <= length ? Math.floor(active) + 1 : length)}`} className="next page-numbers">
            Next
            <NextArrowTwo />
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
