import ServiceThreeAbout from "components/abouts/service-3-about";
import AgencyBrands from "components/brands/agency-brands";
import AgencyCta from "components/cta/agency-cta";
import ServiceThreeCta from "components/cta/service-3-cta";
import CommonFaq from "components/faqs/common-faq";
import ServiceThreeFeature from "components/features/service-3-feature";
import { PortfolioMasonryArea } from "components/portfolios/portfolio-page-com/portfolio-masonry";
// import { PortfolioMasonryArea } from "components/portfolios/portfolio-page-com/portfolio-masonry";
import SEO from "components/seo";
import ServiceThreeServices from "components/services/service-3-services";
import ServiceThreeTestimonial from "components/testimonials/service-3-testimonial";
import ServiceThreeVideoArea from "components/video-area/service-3-video-area";
import SectionWrapper from "elements/section-wrapper";

import FooterSeven from "layout/footers/footer-7";
import Header from "layout/headers/header";
import HeaderSix from "layout/headers/header-6";
import Wrapper from "layout/wrapper";
import CalenderThree from "svg/calender-3";
import CommentBigTwo from "svg/comment-big-2";
import MobileAppThree from "svg/mobile-app-3";
import SeoOptimize from "svg/seo-optimize";

// Banner Image

import bannericon from "assets/img/Main Images/3d/rionickicon.png"
import banner3d from "assets/img/Main Images/3d/video-bg-2.png"

// 3D Service Des

import serviceone from "assets/img/Main Images/3d/3d modeling services.jpg"

import servicetwo from "assets/img/Main Images/3d/3d animation services.jpg"

// Portfolio images 

import portfolio1 from "assets/img/Main Images/3d/Stulized_Granade.jpg"
import portfolio2 from "assets/img/Main Images/3d/Gun.jpg"
import portfolio3 from "assets/img/Main Images/3d/Character_Model_01.jpg"
import portfolio4 from "assets/img/Main Images/3d/Valorant_Gun.jpg"
import portfolio5 from "assets/img/Main Images/3d/Construction cap.jpg"
import portfolio6 from "assets/img/Main Images/3d/Gym machinery.png"
import portfolio7 from "assets/img/Main Images/3d/Product.png"
import portfolio8 from "assets/img/Main Images/3d/Ram_Mandir.jpg"
import portfolio9 from "assets/img/Main Images/3d/Vehicle_01.jpg"
import portfolio10 from "assets/img/Main Images/3d/walkie_talkie.jpg"
import portfolio11 from "assets/img/Main Images/3d/Lost_in_Forest.jpg"
import portfolio12 from "assets/img/Main Images/3d/Gun_02.jpg"

const portfoliio = [
  // APPS
  {
    id: '1',
    img: portfolio1,
    main_img: portfolio1,
    title: 'Magazine Cover',
    category: 'Mockup'
  },
  {
    id: '2',
    img: portfolio2,
    main_img: portfolio2,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '3',
    img: portfolio3,
    main_img: portfolio3,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '4',
    img: portfolio4,
    main_img: portfolio4,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '5',
    img: portfolio5,
    main_img: portfolio5,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '6',
    img: portfolio6,
    main_img: portfolio6,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '7',
    img: portfolio7,
    main_img: portfolio7,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '8',
    img: portfolio8,
    main_img: portfolio8,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '9',
    img: portfolio9,
    main_img: portfolio9,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '10',
    img: portfolio10,
    main_img: portfolio10,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '11',
    img: portfolio12,
    main_img: portfolio12,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '12',
    img: portfolio11,
    main_img: portfolio11,
    title: 'Logo Mockups',
    category: 'Apps'
  },
]

const ListDevelopment = [
  {
    icon: <i class="fi fi-rs-cubes"></i>,
    title: "3D Modeling",
    subtitle:
      "We provide the best 3D modeling services by dealing with 3D projects of any complexity and delivering them on timelines without compromising on the quality of 3D models.",
  },
  {
    icon: <i class="fi fi-rr-running"></i>,
    title: "3D Animation",
    subtitle:
      "Our 3D artists are highly skilled in providing high-quality 3D animation services customized to meet our clients’ requirements and business goals.",
  },
  {
    icon: <i class="fi fi-rr-paintbrush-pencil"></i>,
    title: "Texturing",
    subtitle:
      "We provide 3D texturing services by adding different textures to the 3D models like adding colors, patterns and other details to the models to give the model a realistic look.",
  },
  {
    icon: <i class="fi fi-br-video-camera-alt"></i>,
    title: "3D Product Designing",
    subtitle:
      "We provide 3D product designing services to our clients to give their products a more detailed look and animation, including information about its design, features, and functionality.",
  },
];
export default function ServicesThree() {
  return (
    <Wrapper>
      <SEO
        metaTitle={
          "Best 3D Modeling, 3D Art and 3D Animation Services in India"
        }
        metaDesc={
          "We offer best 3D modeling, best 3D animation, texturing, and product presentation services across the industries."
        }
        metaKeywords={
          "best 3d animation in india, best 3d modeling services in delhi, best 3d motion design, 3d model cost, 3d animation price, free 3d models,  best 3d art design in noida, 3d product animation, 3d texturing services"
        }
        pageTitle={"3D"}
        canonical="https://www.rionick.com/best-3d-services-india"
      />
      <HeaderSix top_bar={false} commonOffCanvas={true} />
      <ServiceThreeVideoArea
        banner_icon= {bannericon}
        video_bg= {banner3d}
        t1="We are here for you."
        t2="Specialize in offering custom 3D designs such as 3D modeling, 3D texturing and 3D animation"
      />
      {/* <ServiceThreeFeature/> */}
      <ServiceThreeServices list={ListDevelopment} />
      <ServiceThreeAbout
        list={[
          "Product visualization",
          "Faster deliveries",
          "Increased accuracy",
          "Customization",
          "Cost reduction",
        ]}
        t1="Does 3D modeling give you an edge over your competitors?"
        p="3D Modelings Services includes but not limited to:"
        about_img={serviceone}
        reverse={true}
      />
      <ServiceThreeAbout
        t1="3D animation videos: why are they so effective for businesses?"
        p="The best 3d animation videos are those which are way more engaging than 2d animation videos as they help in conveying complex ideas with simplicity. 3D animation allows for a great deal of flexibility as you can create custom 3d models or custom 3d characters that you imagine. These 3d animated videos create unique and visually striking marketing materials. 3d animation videos help businesses to communicate their message more effectively, engage their audience more deeply, and stand out in the crowd."
        reverse={false}
        about_img={servicetwo}
      />
      <AgencyCta
        title="Connect with us for captivating 3D visuals"
        content="Let’s discuss your ideas"
        bclr="tp-btnr bg-danger tp-btn-shine-effect"
        element_style={true}
      />

      <PortfolioMasonryArea data={portfoliio} />
      {/* <ServiceThreeTestimonial/>
      <AgencyBrands spacing={true} />
      <CommonFaq />
      <ServiceThreeCta /> */}

      <FooterSeven />
    </Wrapper>
  );
}
